<template>
  <v-container>
    <div class="mt-12" style="height: calc(100vh - 150px);">
      <v-row>
        <v-col cols="12" class="d-flex justify-center align-center">
          <v-avatar size="250">
            <v-container>
              <v-img
                contain
                :src="
                  avatar ? avatar : require('@/assets/avatar_placeholder.png')
                "
              />
            </v-container>
          </v-avatar>
        </v-col>
        <v-col cols="12" class="text-h5 text-center">
          Bem vindo(a) <strong>{{ usuario_name }}</strong
          >, basta clicar e vamos trabalhar!
        </v-col>
        <v-row>
          <v-col>
            <v-btn
              @click="goToDados"
              outlined
              block
              color="secondary"
              class="white--text"
              x-large
            >
              Dados Empresa
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              @click="goToAtividades"
              block
              outlined
              color="secondary"
              class="white--text"
              x-large
            >
              Meu Histórico
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              @click="goToAtividades"
              outlined
              block
              color="secondary"
              class="white--text"
              x-large
            >
              Em Atividade
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              @click="goToSolicitacaoServico"
              block
              outlined
              color="secondary"
              class="white--text"
              x-large
            >
              Novo Serviço
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              @click="goToComentario"
              block
              outlined
              color="secondary"
              class="white--text"
              x-large
            >
              Comentários
            </v-btn>
          </v-col>
        </v-row>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Home",

  data() {
    return {
      apiUrl: null,
    };
  },
  // components: {
  //   Notificacao: () => import("@/components/notificacao/Notificacao.vue"),
  // },

  methods: {
    goToDados() {
      this.$router.push({ path: "/cliente/dados" });
    },

    goToAtividades() {
      this.$router.push({ path: "/cliente/atividades" });
    },

    goToComentario() {
      this.$router.push({
        path: "/cliente/comentarios",
      });
    },
    goToSolicitacaoServico() {
      this.$router.push({
        path: "/cliente/solicitacao-servico",
      });
    },
  },

  computed: {
    ...mapState("Usuario", {
      usuario_name: (state) => state.usuario.name,
      avatar: (state) => state.avatar,
    }),
  },

  mounted() {
    if (process.env.VUE_APP_API_URL) {
      this.apiUrl = process.env.VUE_APP_API_URL;
    }
  },
};
</script>

<style lang="scss"></style>
